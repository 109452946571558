import React, { useState } from "react";
import PropTypes from "prop-types";
import jsonp from "jsonp";
import { css } from "@emotion/react";
import { breakpoints } from "~styles/global";
import Button from "~components/blocks/Button";
import sendToMailchimp from "~utils/sendToMailchimp";
import RichText from "~components/RichText";

const NewsletterSignupForm = ({
  className,
  successMessage,
  buttonText,
  heading,
  centered,
}) => {
  const { mobile } = breakpoints;
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const validate = (e) => {
    e.preventDefault();
    const newErrors = [];

    if (!values.email)
      newErrors.push({
        field: "email",
        message: "The email field is required",
      });

    if (
      values.email &&
      (!values.email.includes("@") || !values.email.includes("."))
    )
      newErrors.push({
        field: "email",
        message: "Please check your email address",
      });

    if (newErrors.length === 0) {
      submit();
    } else {
      setErrors(newErrors);
    }
  };

  const submit = () => {
    sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
      if (err) {
        console.log(err, data);
        setErrors([
          {
            field: null,
            message: "Sorry we can't sign you up right now.",
          },
        ]);
      } else {
        setSuccess(true);
      }
    });
  };

  const errorFields = errors.map((error) => error.field);

  return (
    <div
      className={className}
      css={css`
        position: relative;
        h6 {
          margin-bottom: 18px;
        }
        input {
          ${mobile} {
            margin-bottom: 20px;
          }
        }
      `}
    >
      <h5
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          opacity: ${success ? "1" : "0"};
          transition: opacity 0.3s 0.3s;
          pointer-events: ${success ? "all" : "none"};
        `}
      >
        <RichText content={successMessage} />
      </h5>
      <div
        css={css`
          opacity: ${success ? "0" : "1"};
          transition: opacity 0.3s;
          pointer-events: ${success ? "none" : "all"};
        `}
      >
        {heading && <h6>{heading}</h6>}
        <form onSubmit={(e) => validate(e)} noValidate>
          <input
            type="text"
            name="fname"
            placeholder="First Name"
            onChange={(e) => setValues({ ...values, fname: e.target.value })}
          />
          <input
            type="text"
            name="lname"
            placeholder="Last Name"
            onChange={(e) => setValues({ ...values, lname: e.target.value })}
          />
          <input
            type="email"
            name="email"
            placeholder="Email*"
            css={css`
              border-color: ${errorFields.includes("email")
                ? "var(--orange)"
                : "var(--black)"};
            `}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
          {errors?.length > 0 && (
            <div
              css={css`
                grid-column: span 2;
                margin-top: -10px;
                margin-bottom: 20px;
                color: var(--orange);
              `}
            >
              {errors.map((error) => (
                <p className="h5">{error.message}</p>
              ))}
            </div>
          )}
          <Button
            onClick={() => console.log("values", values)}
            type="submit"
            css={css`
              margin: ${centered ? "0 auto" : "0"};
            `}
          >
            {buttonText ? buttonText : "Go"}
          </Button>
        </form>
      </div>
    </div>
  );
};

NewsletterSignupForm.propTypes = {
  successMessage: PropTypes.array,
  buttonText: PropTypes.string,
  centered: PropTypes.bool,
};

NewsletterSignupForm.defaultProps = {};

export default NewsletterSignupForm;
