exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-reset-[id]-[code]-js": () => import("./../../../src/pages/account/reset/[id]/[code].js" /* webpackChunkName: "component---src-pages-account-reset-[id]-[code]-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-service-enquiry-js": () => import("./../../../src/pages/design-service-enquiry.js" /* webpackChunkName: "component---src-pages-design-service-enquiry-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-showrooms-js": () => import("./../../../src/pages/showrooms.js" /* webpackChunkName: "component---src-pages-showrooms-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-brand-index-js": () => import("./../../../src/templates/Brand/index.js" /* webpackChunkName: "component---src-templates-brand-index-js" */),
  "component---src-templates-modular-page-index-js": () => import("./../../../src/templates/ModularPage/index.js" /* webpackChunkName: "component---src-templates-modular-page-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-products-index-js": () => import("./../../../src/templates/Products/index.js" /* webpackChunkName: "component---src-templates-products-index-js" */)
}

